import { authAPI } from "../api/api";
import { localStorageService } from "./localStorageService";

const TokenValidate = async () => {

    let access_token = localStorageService.getAccessToken();
    let refresh_token = localStorageService.getRefreshToken();
    
    //generating new accessToken
    if (access_token === null) {
        if (refresh_token === 'undefined' || !refresh_token) {
            return false
        }
        else if (refresh_token !== null || refresh_token !== 'undefined') {
            let refresh =  await authAPI.refreshToken(refresh_token);
            if(refresh.data.refreshToken.statusCode === 200){
                localStorageService.setToken(refresh.data.refreshToken.result);
                return true
            }
            else if(refresh.data.refreshToken.statusCode === 403){
                return false
            }

        }
    }
    return true
};
export default TokenValidate;