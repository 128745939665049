import React, { useEffect, useState } from 'react';
import { authAPI } from '../api/api';
import PNZLogin from '../components/PNZLogin';
import { localStorageService } from '../utils';

function Login() {
   
    const [redirectLink, setRedirectLink] = useState('');
    useEffect(() => {
        async function consent() {
            const accountconsent = await authAPI.accountconsent();
            // console.log(accountconsent.data.consentId)
            const initiatecodegrant = await authAPI.initiatecodegrant(accountconsent.data.consentId);
            // console.log(initiatecodegrant);
            setRedirectLink(initiatecodegrant.data.redirect);
        }
        consent()
    }, [])

    const onSuccess = async (response) => {
        // console.log(response.code, "response access");
        const accessToken = await authAPI.accesstoken(response.code)
        localStorageService.setToken(accessToken.data.accessTokenObject);
    };
    const onFailure = response => console.error(response);
    return (
        <>
            {redirectLink &&
                <PNZLogin redirectUri={redirectLink}
                    onSuccess={onSuccess}
                    onFailure={onFailure} />
            }
        </>
    );
}

export default Login;
