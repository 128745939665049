import axios from 'axios';

//change this url on deployment 
const baseURL = 'https://bnz.dev.apimatic.io/api';

export const authAPI = {
    accountconsent() {
        return axios.post(`${baseURL}/accountconsent`)
    },
    initiatecodegrant(consentId) {
        return axios.get(`${baseURL}/initiatecodegrant?consentId=${consentId}`)
    },
    accesstoken(urlCode) {
        // const accesstoken = JSON.stringify();
        return axios.post(`${baseURL}/accesstoken`, { code: urlCode })
    },
    refreshToken(refreshtoken) {
        return axios.post(`${baseURL}/refreshToken`, { refreshToken: refreshtoken })
    }
}

export const bulkEnpoints = {
    consentedaccountinfo(accessToken) {
        return axios.get(`${baseURL}/consentedaccountinfo?accessToken=${accessToken}`)
    }
    // consentedaccountinfo() {
    //     return axios.get(`${baseURL}/consentedaccountinfo`)
    // }
}
