import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopupWindow from './PopupWindow';
import ModalWindow from './ModalWindow';

class PNZLogin extends Component {

  static propTypes = {
    buttonText: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    redirectUri: PropTypes.string.isRequired,
    onRequest: PropTypes.func,
  }

  static defaultProps = {
    buttonText: 'Sign in with PNZ',
    onRequest: () => { },
    onSuccess: () => { },
    onFailure: () => { },
  }
  state = {
    modalShown: true
  };
  onBtnClick = () => {
    const { redirectUri } = this.props;
    const popup = this.popup = PopupWindow.open(
      'pnz-oauth-authorize',
      `${redirectUri}`,
      { height: 1000, width: 600 }
    );

    this.onRequest();
    popup.then(
      data => this.onSuccess(data),
      error => this.onFailure(error)
    );
  }

  onRequest = () => {
    this.props.onRequest();
  }

  onSuccess = (data) => {
    // console.log(data)
    this.setState({modalShown:false})
    if (!data.code) {
      return this.onFailure(new Error('\'code\' not found'));
    }

    this.props.onSuccess(data);
  }

  onFailure = (error) => {
    this.setState({modalShown:false})
    this.props.onFailure(error);
  }

  render() {
    const { className, buttonText, children} = this.props;
    const attrs = { onClick: this.onBtnClick };

    // if (className) {
    //   attrs.className = className;
    // }

    return <ModalWindow at= {attrs} shown={this.state.modalShown} />
    // return <button {...attrs}>{ children || buttonText }</button>;
  }
}

export default PNZLogin;
