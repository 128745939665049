import React from 'react';
import Home from './pages/Home';
import { Routes, Route, Navigate} from "react-router-dom";

const App = () => {
    const shouldRedirect = true;
  
    return (
      <>
        <Routes>
          <Route index element={
              shouldRedirect ? (
                <Navigate replace to="/callback" />
              ) : (
                <ErrorPage />
              )
            } />
          <Route path="callback" element={<Home />} />
        </Routes>
      </>
    );
  };
  
  const ErrorPage = () => {
    return (
      <>
        <h2>Reload</h2>
      </>
    );
  };
  
  export default App;
  
