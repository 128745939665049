import React, { useState , useEffect} from 'react';
import TokenValidate from '../utils/TokenValidate';
import Login from './Login';
import '../App.css';
import Portal from './Portal';

function Home() {
    const [invalidToken, setInvalidToken] = useState(false)

    useEffect(() => {
        async function checkToken() {
            const isValidToken = await TokenValidate()
            if (!isValidToken) {
                setInvalidToken(true)
            }
        }
        checkToken()
    }, [])
    
    return (
        <div className='App'>
            {invalidToken && <Login />}
            <div>
                <Portal />
            </div>
        </div >
    );
}

export default Home;


