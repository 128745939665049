import React, { useEffect } from "react";
import { localStorageService } from "../utils";
import './embed.css';

const Portal = () => {

    let flag = true;

    useEffect(() => {
        var script = document.createElement("script");
        script.src = "https://dxjs.apimatic.io/v7/static/js/portal.v7.js";
        script.onload = function () {
            /*global APIMaticDevPortal */
            APIMaticDevPortal.show(
                {
                    "container": "apimatic-widget",
                    "portalStyle": "default",
                    "codegenApiRoutes": {
                        "docsgen": "/api/api-entities/SDabtoXjT5O2Ok3fIaFaRwDJ1CRzwg8BLXWCcPg4tr_R_IwJiEtanqQb08PcIZqJ/portal-artifacts/docs/generated-file?template={template}",
                        "codegen": "/api/api-entities/SDabtoXjT5O2Ok3fIaFaRwDJ1CRzwg8BLXWCcPg4tr_R_IwJiEtanqQb08PcIZqJ/portal-artifacts/sdks/generated-file?template={template}",
                        "transform": "/api/api-entities/SDabtoXjT5O2Ok3fIaFaRwDJ1CRzwg8BLXWCcPg4tr_R_IwJiEtanqQb08PcIZqJ/portal-artifacts/specs/generated-file?format={format}",
                        "apiProxy": "https://proxy.apimatic.io/api/proxy"
                    },
                    "apiKey": "PkUYNyvjYA7DfFBwoKTjTApm1R0X_3p2YmbGWgDomZ9XceM_UoE8VWPgJ8x82U0_nXalQDLnmOglUSVOZ900jw**",
                    "baseUrl": "https://www.apimatic.io",
                    "enableExport": true,
                    "renameHttpToRest": false,
                    "enableConsoleCalls": true,
                    "useProxyForConsoleCalls": true,
                    "initialPlatform": "http_curl_v1",
                    "languageSettings": {
                        "http_curl_v1": {
                            "disableSdkDownload": true
                        },
                        "cs_net_standard_lib": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        },
                        "java_eclipse_jre_lib": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        },
                        "php_generic_lib_v2": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        },
                        "python_generic_lib": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        },
                        "ruby_generic_lib": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        },
                        "ts_generic_lib": {
                            "disableSdkDownload": false,
                            "sdkDownloadLink": ""
                        }
                    },
                    "allowedExportFormats": [
                        "postman10",
                        "postman20",
                        "openapi31json",
                        "openapi31yaml",
                        "openapi3json",
                        "openapi3yaml",
                        "swagger20",
                        "swaggeryaml",
                        "swagger10",
                        "raml",
                        "raml10",
                        "apiblueprint",
                        "wadl2009",
                        "apimatic",
                        "wsdl",
                        "insomnia",
                        "insomniayaml"
                    ],
                    "themeOverrides": {
                        "themeType": "cool",
                        "palette": {
                            "primaryColor": "#0c7ff2",
                            "linkColor": "#00C7D4"
                        },
                        "fontSource": [],
                        "cssStyles": {
                            "headings": {
                                "fontFamily": "'Rubik', sans-serif",
                                "h1": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "27px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                },
                                "h2": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "24px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                },
                                "h3": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "21.36px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                },
                                "h4": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "19px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                },
                                "h5": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "16.68px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                },
                                "h6": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "15px",
                                    "fontWeight": "500",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.3"
                                }
                            },
                            "body": {
                                "fontFamily": "'Rubik', sans-serif",
                                "text1": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "15px",
                                    "fontWeight": "400",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.75"
                                },
                                "text2": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "13.33px",
                                    "fontWeight": "400",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.75"
                                },
                                "text3": {
                                    "fontFamily": "'Rubik', sans-serif",
                                    "fontSize": "11.85px",
                                    "fontWeight": "400",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.75"
                                }
                            },
                            "code": {
                                "fontFamily": "'SFMono-400', Consolas, 'Liberation Mono', Menlo, Courier",
                                "blockCode": {
                                    "fontFamily": "'SFMono-400', Consolas, 'Liberation Mono', Menlo, Courier",
                                    "fontSize": "15px",
                                    "fontWeight": "400",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.75"
                                },
                                "inlineCode": {
                                    "fontFamily": "'SFMono-400', Consolas, 'Liberation Mono', Menlo, Courier",
                                    "fontSize": "15px",
                                    "fontWeight": "400",
                                    "fontStyle": "normal",
                                    "lineHeight": "1.75"
                                }
                            }
                        }
                    }
                }
            );
        };

        document.head.appendChild(script);
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const S = () => {

        if (flag) {
            setTimeout(() => {
                const A = document.getElementById("code-config-button");
                if (!A) return;
                A.click();
                const I = document.getElementsByClassName("sc-bczRLJ sc-fSUSjI jsOVwQ gpCoGB");
                if (!I) return;
                // console.log(I, "I")
                if (I.length !== 0) {
                    I[0].style.display = "none";
                }
                A.style.display = "block";
                const inputElem = document.querySelector('input[label="AccessToken"]');
                if (!inputElem) return;
                const M = new Event("input", { bubbles: true });
                const B = inputElem.value;
                if (localStorageService.getAccessToken() !== null){
                    flag = false;
                    inputElem.value = localStorageService.getAccessToken();
                    inputElem._valueTracker.setValue(B);
                    inputElem.dispatchEvent(M);
                    inputElem.focus();
                    const T = document.getElementsByClassName("sc-kDDrLX imnNaW");
                    // console.log(T);
                    T[0].click();
                }
            }, 2000);
        }
    };
    return (
        <>
            <div id="apimatic-widget" className="h-screen" onClick={S} onKeyDown={S} >
            </div>
        </>
    )
};
export default Portal;
