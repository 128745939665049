function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)

    // compare the expiry time of the item with the current time if at less than 10 seconds to expire invalidate the accesstoken
    if (item.expiry - (Math.floor(Date.now() / 1000)) < 10) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const localStorageService = (function () {
    let _service

    function _getService() {
        if (!_service) {
            _service = this
            return _service
        }
        return _service
    }

    function _setToken(tokenObj) {

        //epoch timestamp in seconds 
        const access_token = {
            value: tokenObj.accessToken,
            expiry: Math.floor(Date.now() / 1000) + tokenObj.expiresIn,
        }
        localStorage.setItem('access_token', JSON.stringify(access_token))
        localStorage.setItem('refresh_token', tokenObj.refreshToken)
    }

    function _getAccessToken() {
        return getWithExpiry('access_token')
        // return localStorage.getItem('access_token')
    }

    function _getRefreshToken() {
        return localStorage.getItem('refresh_token')
    }

    function _clearToken() {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    }

    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        clearToken: _clearToken
    }
})()