import React from 'react';
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from 'react-bootstrap/Button';

const ModalWindow = ({at,shown}) => {
    // const[at,shown] = props;
    const [isOpen, setIsOpen] = React.useState(true);

    const hideModal = () => {
        setIsOpen(false);
    };

    return shown?(
            <Modal show={isOpen} >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button {...at} >Sign in With PNZ</Button>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        ): null;
    
};

export default ModalWindow;